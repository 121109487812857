import type { HubResponseProps } from '@readme/iso';

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import type { ProjectContextValue, UserContextValue } from '@core/context';
import { ProjectContext, UserContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useUserPermissions from '@core/hooks/useUserPermissions';

import { useSuperHubStore } from '.';

export interface InitializeSuperHubStore {
  children: React.ReactNode;
  currentVersion: HubResponseProps['gitVersion'];
}

/**
 * Middleware component connects to our App project and user contexts to then
 * initialize our SuperHub store and make it ready for this particular project.
 */
export function InitializeSuperHubStore({ children, currentVersion }: InitializeSuperHubStore) {
  const { isServer } = useEnvInfo();
  const [initialize, isReady] = useSuperHubStore(s => [s.initialize, s.isReady]);
  const { isAdminUser } = useUserPermissions();
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { user } = useContext(UserContext) as unknown as UserContextValue;
  const location = useLocation();

  // To enable SSR, we must call this inline rather than inside an effect. On
  // the server, ignore the "ready" state b/c our global stores on the server
  // persist indefinitely and won't ever reset the "ready" state to false.
  if (isServer || !isReady) {
    initialize({
      currentVersion,
      isAdminUser,
      isEnterpriseGroup: !!project.childrenProjects?.length,
      isEnterpriseChild: !!project.parent,
      // Because hash route values are not available on the server, initialize
      // both SSR and the first client render without the location.hash to avoid
      // SSR/CSR mismatches.
      location: [location.pathname],
      projectFlags: project.flags,
      subdomain: project.subdomain,
      user: {
        id: user?.teammateUserId || null,
        name: user?.name || null,
      },
    });
  }

  return <>{children}</>;
}
